/* Phase III */
#phaseiii .phaseiii-title {
    width: 100%;
    background-color: #E6EAEE;
    padding: 20px 30px;
    margin-bottom: 10px;
    position: relative;
}
#phaseiii .phaseiii-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
#phaseiii .phaseiii-column {
    width: 33.333333%;
    background-color: #E6EAEE;
    padding: 20px 20px;
    position: relative;
}
#phaseiii .flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    position: relative;
}
#phaseiii .white-bg {
    background-color: #FFFFFF;
}
#phaseiii .line-parent {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
#phaseiii .phaseiii-textbox {
    width: 88%;
    padding: 20px 20px;
    background: rgb(255,108,14);
    background: linear-gradient(180deg, rgba(255,108,14,1) 0%, rgba(148,58,244,1) 100%);
    z-index: 1;
}

#phaseiii .phaseiii-textbox-line {
    width: 50%;
    height: 4px;
    position: absolute;
    right: 0;
    margin-left: auto;
    background: rgb(255,108,14);
    background: linear-gradient(280deg, rgba(255,108,14,1) 0%, rgba(148,58,244,1) 100%);
}
#phaseiii .left-line {
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgb(255,108,14);
    background: linear-gradient(180deg, rgba(255,108,14,1) 0%, rgba(148,58,244,1) 100%);
}
#phaseiii .top-line {
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: rgb(255,108,14);
    background: linear-gradient(90deg, rgba(255,108,14,1) 0%, rgba(148,58,244,1) 100%);
}
#phaseiii .phaseiii-textbox h2, #phaseiii .phaseiii-textbox p {
    color: #ffffff;
    text-align: center;
    line-height: 1.2;
}
#phaseiii .phaseiii-textbox h2 {
    margin-bottom: 5px;
}

#phaseiii .phaseiii-footnote {
    width: 100%;
    margin-top: 10px;
}
#phaseiii .phaseiii-footnote p{
    font-size: 11px;
    line-height: 1.2;
    margin: 0;
}
#phaseiii .phaseiii-footnote a {
    font-size: 11px;
    line-height: 1.2;
    margin: 0;
    color: #002F55;
}
#phaseiii .phaseiii-footnote a:link { color: #002F55; }
#phaseiii .phaseiii-footnote a:visited { color: #002F55; }
#phaseiii .phaseiii-footnote a:hover { color: #002F55; }
#phaseiii .phaseiii-footnote a:active { color: #002F55; }

#phaseiii .phaseiii-arrow {
    width: 100%;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 28 16' style='enable-background:new 0 0 28 16;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23002F55;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='14,15.6 0.9,2.5 3.1,0.4 14,11.3 24.9,0.4 27.1,2.5 '/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 28px 16px;
    background-position: center;

}

#phaseiii h1 {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 22px;
    line-height: 1.4;
    color: #002F55;
    text-transform: uppercase;
    text-align: left;
    margin: 0;
    padding: 0;
}

#isi h1 {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 22px;
    line-height: 1.4;
    color: #002F55;
    text-transform: uppercase;
    text-align: left;
    margin: 0;
    padding: 0;
}
#isi h2, #phaseiii h2 {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.4;
    color: #002F55;
    text-align: left;
    margin: 0;
    padding: 0;
}
#isi h3, #phaseiii h3 {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.4;
    color: #002F55;
    text-align: left;
    margin: 0;
    padding: 0;
}
#isi p, #phaseiii p {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: #002F55;
    text-align: left;
    margin: 0;
    padding: 0;

}
#isi ul {
    font-size: 14px;
}

#isi .warning-box {
    width: 100%;
    padding: 20px;
    margin: 20px 0px;
    border: 1px solid #002F55;
}
#isi .disclaimer {
    width: 100%;
    padding: 20px;
    margin: 20px 0px 40px;
    background-color: #E6EAEE;
}
#isi .disclaim {
    width: 100%;
    margin: 0px 0px;
}
#isi .mb10 {
    margin: 0px 0px 10px 0px !important;
}
#isi .last {
    margin-bottom: 10px !important;
}
#isi .isi-footnote {
    margin: 20px 0px 0px 0px;
    padding: 20px 0px 20px 0px;
}
#isi .isi-footnote p {
    text-align: center;

}


.warning {
    width: 100%;
    margin-top: 10px;
}
.warning-text {
    width: 100%;
}
.warning-text h1 {
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #002F55;
}
.warning-text p {
    width: 100%;
    font-size: 22px;
    font-weight:normal;
    text-align: center;
    color: #002F55;
}
.warning-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.warning-buttons .btn {
    width: 48%;
}
.warning-buttons .btn.red {
    background-color: #D31B1B;
}
.warning-buttons .btn.red:hover {
    background-color: #E98D8D;
}


@media only screen and (max-width: 960px) {

    .modal-content-phase {
        max-height: unset;
    }

    #phaseiii .phaseiii-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #phaseiii .phaseiii-column {
        width: 100%;
        margin-bottom: 20px;
    }

    #phaseiii .phaseiii-content :nth-child(1) { 
        order: 0; 
    }
    #phaseiii .phaseiii-content :nth-child(0) { 
        order: 2; 
    }
    #phaseiii .phaseiii-content :nth-child(2) { 
        order: 1;
        display: block;
    }

    #phaseiii .col1 { 
       margin-bottom: 40px;;
    }


    #phaseiii .phaseiii-column .flex-column {
        display: flex;
    }
    

    #phaseiii .phaseiii-arrow {
        margin: 20px 0px;
    }

    #phaseiii .phaseiii-textbox {
        width: 100%;
    }

    #phaseiii .top-line {
        bottom: 0;
        top: unset;
    }

    #phaseiii .phaseiii-textbox-line {
        width: 4px;
        height: 100px;
        position: absolute;
        right: 50%;
        top: -44px;
        margin-left: auto;
    }


}