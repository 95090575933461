.link-img {
    width: 100%;
}

.link-wrap {
    width: 18%;
    color: #ffffff;
    position: relative;
}
.link-click-wrap {
    position: relative;
}
.link-click {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.link-click:hover {
    background-color: rgba(255, 255, 255, .3);
}
.link-wrap img {
    display: block;
}
.link-title {
    width: 100%;
    padding: 10px 5px 10px 5px;
    font-size: 24px;
    text-align: center;
    color: #ffffff;
    background-color: #00173C;
}
.link-progress {
    width: 100%;
}
.progress-wrap {
    width: 100%;
    height: 8px;
    background-color: #1A4466;
}
.progress-txt {
    width: 100%;
    padding: 15px 20px;
    font-size: 20px;
    text-align: center;
    background-color: #002F55;
}
.progress {
    width: 50%;
    height: 8px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f7ca21+0,ff6c0e+100 */
    background: linear-gradient(to right,  #f7ca21 0%,#ff6c0e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}




@media only screen and (max-width: 1200px) {

    .link-title {
        font-size: 20px;
    }

    .progress-txt {
        font-size: 18px;
    }

}

/* mobile */
@media only screen and (max-width: 960px) {

    .link-wrap {
        width: 48%;
    }

}


/* mobile */
@media only screen and (max-width: 720px) {

    .link-wrap {
        width: 60%;
        margin-bottom: 20px;
    }

}

@media only screen and (max-width: 460px) {

    .link-wrap {
        width: 100%;
        margin-bottom: 20px;
    }

}