.quiz-title {
    margin-bottom: 10px;
}

h1 {
    font-size: 36px;
    line-height: 1.2;
    color: #FF6C0E;
    text-align: center;
}

p {
    font-size: 20px;
    text-align: center;
}

.swiper-autoheight .swiper-wrapper {
    /* min-height: 100vh !important; */
    height: fit-content !important;
}


@media only screen and (max-width: 1200px) {

    h1 {
        font-size: 36px;
        text-align: center;
    }
    p {
        font-size: 24px;
    }
    .question-wrap {
        max-width: 1024px;
    }
    .footnote-wrap {
        max-width: 1024px;
    }

}

@media only screen and (max-width: 1200px) {

    h1 {
        font-size: 36px;
        text-align: center;
    }
    p {
        font-size: 24px;
    }
    .question-wrap {
        max-width: 960px;
    }
    .footnote-wrap {
        max-width: 960px;
    }

}



@media only screen and (max-width: 720px) {



}