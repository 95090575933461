footer {
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding: 24px; */
    /* position: fixed;
    left: 0px;
    bottom: 0px; */
    /* background-color: rgba(0, 47, 85, .8) */
    background-color: #002F55;
}
.footer-wrap {
    width: 100%;
}
.footer-button-wrap {
    width: 100%;
    /* max-width: 1920px; */
    display: flex;
    justify-content: space-between;
}
.footer-footnote {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    margin-top: 15px;
    padding: 15px 20px;
    background-color: rgba(0, 0, 37, .3);
}
.footer-footnote p {
    font-size: 14px;
    line-height: 1.2;
    text-align: right;
    margin-bottom: 0px;
}

.footer-footnote a { color: #ffffff; }
.footer-footnote a:link { color: #ffffff; }
.footer-footnote a:visited { color: #ffffff; }
.footer-footnote a:hover { color: #ffffff; }
.footer-footnote a:active { color: #ffffff; }



.footer-footnote .jobcode {
    color: #738FAF;
}
.footer-logo {
    width: 200px;
    margin-right: 20px;
}
.footer-btns {
    padding: 0px 20px;
    display: flex;
    justify-content: center;
}
.footer-btn {
    display: flex;
    align-items: center;
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
    padding: 14px 30px;
    margin-right: 20px;
    cursor: pointer;
    background-color: #1A4466;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.footer-btn:hover {
    background-color: #8DA2B3;
}
.new-user {
    margin-right: 0px;
    background-color: #943AF4;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.new-user:hover {
    margin-right: 0px;
    background-color: #CA9DFA;
}

@media only screen and (max-width: 1200px) {

    .footer-btn {
        padding: 12px 20px;
        font-size: 14px;
    }

}

/* mobile */
@media only screen and (max-width: 1070px) {

    footer {
        position: relative;
        padding: 0px;
        background-color: #002F55;
    }
    .footer-btns {
        width: 100%;
        flex-direction: column;
        /* padding: 0px 0px; */

    }
    .footer-button-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .footer-btn {
        /* width: 100%;
        flex-direction: column; */
        padding: 14px 30px;
        margin-right: 0;
        margin-top: 10px;
    }
    .footer-footnote {
        flex-direction: column;
        align-items: flex-start;
    }
    .footer-text p:first-child {
        margin-top: 20px;
    }
    .footer-text p {
        text-align: left;
        margin-bottom: 8px;
    }

}