.video-holder {
    width: 100%;
    margin: 40px 0px;
}

.video-js * {
    /* font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif !important; */
}

.vjs-poster img {
    margin-top: 0 !important;
    object-fit: cover !important;
}


.video-js.video-js .vjs-big-play-button {
    height: 50px !important;
    width: 50px !important;
    border-radius: 50% !important;
    font-size: 2rem !important;
    line-height: 1.4em !important;
}



.video-js.video-js .vjs-big-play-button:before {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -30%) !important;
    
}

.video-js .vjs-big-play-button {

    left: calc(50% + 25px);
}

.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
   display: block !important;
}

.video-js.vjs-ended .vjs-poster {
    display: block !important;
  }

  .vjs-has-ended .vjs-poster {
    display: block !important;
}
