body {
  font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #ffffff;
  background-color: #FFFFFF;
}

/* .site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
} */
/* .app {
  flex: 1;
} */
.loading-elem {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #002F55;
}
.container-white {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #FFFFFF;
}
.content-wrap {
  width: 100%;
  /* height: calc(100vh - 212px); */
  /* max-width: 1920px; */
  position: relative;
  /* overflow-y: scroll; */
}
.content {
  width: 100%;
  position: relative;
}
  
  @media screen and (max-width: 960px) {
  
    /*  */

  }
  
