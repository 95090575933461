header {
    width: 100%;
    height: 80px;
    position: fixed;
    display: flex;
    justify-content: center;
    padding: 20px 20px 0px 20px;
    /* background-color: #002F55; */
    z-index: 100;
}
.header-wrap {
    width: 100%;
    /* max-width: 1920px; */
    display: flex;
    justify-content: space-between;
    /* background-color: rgba(255, 255, 255, .8); */
}
.home-btn {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.2;
    color: #ffffff;
    padding: 10px 40px;
    margin-right: 20px;
    border-radius: 100px;
    background-color: #943AF4;
    align-self:flex-start;
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.home-btn:hover {
    background-color: #CA9DFA;
}

.solid-head {
    /* background-color: #002F55; */
    background-color: rgba(0, 47, 85, .8);
}


@media only screen and (max-width: 1200px) {

    header {
        height: 95px;
    }

}
@media only screen and (max-width: 460px) {

    .home-btn {
        font-size: 16px;
        padding: 10px 20px;
        margin-right: 0px;
    }
    .logo {
        height: 40px;
    }

}