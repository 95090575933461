form.form input, form.form select { 
    width: 100%;
    height: 50px;
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.2;
    color:#002F55;
    background-color:#FFFFFF;
    border: none;
    -moz-border-radius:0px;
    -webkit-border-radius:0px;
    border-radius:0px;
    padding-top:10px;
    padding-bottom:10px;
    padding-left:20px;
    padding-right:20px;
}

form.form select { 
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

form.form .submit {
    background-color:#943AF4;
    border:solid 1px #943AF4;
    color: #ffffff;
    text-transform: uppercase;
}
form.form .submit:hover {
    background-color:#CA9DFA;
    border:solid 1px #943AF4;
}

form.form p label {
    font-size:14px;
    color:#002F55;
    font-weight:normal;
    padding-top:0px;
    padding-bottom:0px;
}
.form p.submit input {
    background-color:#943AF4;
    border:solid 1px #943AF4;
    font-size:16px;
    color:#FFFFFF;
    font-weight:bold;
    padding-top:8px;
    padding-bottom:8px;
    padding-right:25px;
    padding-left:25px;
    -moz-border-radius:0px;
    -webkit-border-radius:0px;
    border-radius:0px;
}
.form p.submit {
    margin-top:0px;
    margin-bottom:0px;
    text-align:left;
}
form.form p.required label, form.form span.required label {
    font-size: 14px;
}
form.form p {
    font-size: 14px;
    font-weight: regular;
    text-align: left;
    margin-top: 5px;
}

.form-field-parent {
    width: 100%;
    margin-bottom: 2%;
}

.entry {
    width: 48%;
    display: flex;
    flex-direction: column;
}
.two-col-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.one-col-input input {
    width: 100%;
}
.twocol {
    width: 48%;
}
.onecol {
    width: 100%;
}   

.form-fdbk {
    width: 100%;
}
.form-fdbk p {
    font-size: 14px;
    background-color: #FE6D11;
    text-align: left;
    margin: 0;
    margin: 20px 0px;
    padding: 10px 20px;
}




@media only screen and (max-width: 1200px) {

    .entry {
        width: 49%;
    }

}


@media only screen and (max-width: 720px) {

    .form-field-parent {
        width: 100%;
        margin-bottom: 10px;
    }

    .entry {
        width: 100%;
        margin-bottom: 10px;
    }

    .two-col-input {
        width: 100%;
        display: block;
        justify-content: space-between;
    }
    
    

}